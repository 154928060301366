import "./FirstLayer.css";

import logo from "../../Images/AboutMeImages/logo.jpg";

const FirstLayer = () => {
  return (
    <div className="firstLayer">
      <div className="container col-lg-8 col-md-5 col-sm-4">
        {/* Heading */}
        <h1>
          <b>
            About me
          </b>
        </h1>

        {/* Image that contains my logo */}
        <img src={logo} className="logo-about" alt="" height={200}></img>

        {/* Paragraph containing a short description of what I'm doing */}
        <h4>
          My name is Nathan Lapak and I am a Filipino-Canadian pursuing a career in tech.
        </h4>
      </div>
    </div>
  );
};

export default FirstLayer;
