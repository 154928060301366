import FirstLayer from "../Components/Homepage/FirstLayer"
import Project1 from "../Components/Projects/Project1"
import Project2 from "../Components/Projects/Project2"
import Project3 from "../Components/Projects/Project3" 
import Technologies from "../Components/Homepage/Technologies"

const Homepage = () => {
    return (
        <div>
            <FirstLayer></FirstLayer>
            <Technologies />
            <Project1 />
            <Project2 />
            <Project3 />
        </div>
    )
}

export default Homepage