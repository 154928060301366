import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import "./Project2.css"
import video from  "../../Images/Earsketch-pictures/CMPUT301.mp4"

const Project2 = () => {

    const [open, setOpen] = useState(false);

    return (
        <div className="container col-lg-10 col-md-10 col-sm-4">

            <div className="earsketch">

                {/* Earsketch section */}
                <div>

                    {/* Text for project 1 header */}
                    <div className='header'>
                        <h1>Project 2: Lottery System Appplication</h1>
                        <Button onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
                            Click to expand!
                        </Button>
                    </div>

                    {/* Controls the collapse animation for the button */}
                    <Collapse in={open}>
                        <div id="example-collapse-text">
                            <h3><i>Android Studio Application for CMPUT 301 Lab</i></h3>

                            <h4>Here is a quick demo of the app.</h4>
                            <video  src={video} width="300" height="200" controls></video>


                            {/* About section */}
                            <h2 className="AppInfo301">App info</h2>
                            <p>
                                This app was created by me and my other lab members for the CMPUT 301 lab project. We made this app in a group
                                of 6, and we had to demo the application to our lab and the TAs at the end of the semester. We used Github, Figma,
                                and Android Studio to develop this app. 

                                <br/>
                                <br/>
                                The description of the app is as follows: 
                                "We want a mobile application where people can sign up for events at community centres that are popular and fill up fast. 
                                We want to allow people with limitations such as work, disability, etc. to be able to sign up for these events fairly and not 
                                have to sit refreshing a webpage until they can get a chance at reserving a spot. How? Lottery! If I am running swimming lessons for 20 kids, 
                                I will post my event or series of events and I will let everyone join the waiting list for a period of a week."

                                <br/>
                                <br/>

                                For more details, you can check our Github repo or the actual course description using the buttons below.
                            </p>

    

                            {/* Buttons that link to earsketch's websites */}
                            <Button className="m-3" href="https://github.com/CMPUT301F24rocket/rocket-launch">Github repo</Button>
                            <Button className="m-3" href="https://apps.ualberta.ca/catalogue/course/cmput/301">Course details</Button>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>

    )
}

export default Project2