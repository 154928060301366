import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import "./Project3.css"


import pic1 from "../../Images/Earsketch-pictures/AmazonFuture.png"
import pic2 from "../../Images/Earsketch-pictures/EarSketch.png"

import pic4 from "../../Images/Earsketch-pictures/EarSketch-code.png"
import pic3 from "../../Images/Earsketch-pictures/EarsketchLogo.png"

const Project3 = () => {

    const [open, setOpen] = useState(false);
    
    return (

        <div className="NumberSystems container col-lg-10 col-md-10 col-sm-4">

            {/* Header */}
            <div className="header">
                <h1>Project 3: Music Composition</h1>
                <Button onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
                    Click to expand!
                </Button>
            </div>

            {/* Actual content */}
            <Collapse in={open}>
                <div className='NumberSystem-content'>
                    <h2 className='mt-5'>"Create a song in Python using hip-hop and pop samples"</h2>
                    <img src={pic1} className="w-25 p-5" alt=""></img>
                    <img src= {pic3} className="w-25 p-5" alt=""></img>

                    <h2>Example of the Digital Audio Station and Python code</h2>
                    <img src={pic2} className="w-50 p-5" alt=""></img>
                    <img src= {pic4} className="w-50 p-5" alt=""></img>

                    <h1>Project description</h1>
                    <p>
                    This project was created when I was in high school and is the first time I made money from programming. 
                    The song I composed was the Runner-Up to the Amazon Future Engineer “Your Voice is Power” competition for Fall 2021, where
                    I won 150$ for placing shared 2nd place. This song was composed using Earsketch and Python.
                        <br />
                    </p>

                    {/* Buttons that link to earsketch's websites */}
                    <Button className="m-3" href="https://www.amazonfutureengineer.ca/your-voice-is-power-fall-2021-winners-announced">Blog post</Button>
                    <Button className="m-3" href="https://earsketch.gatech.edu/earsketch2/?sharing=MVt9pMW2-tdiEhJGZM6vXQ">Song</Button>
                </div>
            </Collapse>
        </div>
    )
}

export default Project3