import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import "./Project1.css";

import RISCVLogo from "../../Images/ReceiptApp-images/RISC-V.png"
import Homepagepic from "../../Images/ReceiptApp-images/Homepage-pic.png"


const Project1 = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="container col-lg-10 col-md-10 col-sm-4">

      <div className="showcase">
          <h1>
          <b>Project showcase</b>
          </h1>
          <h4>Here are some of my projects I have created</h4>
      </div>

      <div className="HealthHackED">
        <div>
          <div className="header">
            <h1>
              Project 1: RISC-V Instruction Converter
            </h1>
            <Button
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Click to expand!
            </Button>
          </div>

          <Collapse in={open}>
            <div id="example-collapse-text">
              <h3>
                <i>
                  "Decode RISC-V Instructions or encode a binary or hexadecimal number"
                </i>
              </h3>
              <img src={RISCVLogo} className="w-25 p-4" alt=""></img>

              <div className="receipt-screenshots">
                <h2>
                  <b>Homepage screenshots</b>
                </h2>
                <img className="w-50 p-5" src={Homepagepic} alt=""></img>
              </div>

              <h2>About this project</h2>
              <p>
                This project is a Website that decodes and encodes RISC-V Assembly instructions with its hexadecimal or binary representation. 
                After taking CMPUT 229, I wanted to build a project related to the class. So, I created this website using Nextjs, Typescript and Tailwind.
                I also wrote over 50+ test cases to ensure that the encode and decode functions work properly.
              </p>

              <Button
                className="m-3"
                href="https://risc-vconverter.vercel.app/"
              >
                Deployment 
              </Button>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Project1;
