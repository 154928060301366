import "./Secondlayer.css";

import { GoLocation } from "react-icons/go";
import { RiComputerLine } from "react-icons/ri";
import { IoShareSocialSharp } from "react-icons/io5";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { GoSmiley } from "react-icons/go";
import Slider from "react-slick";

import grad1 from "../../Images/Grad/Grad1.JPG"
import grad2 from "../../Images/Grad/Grad2.JPG"
import grad3 from "../../Images/Grad/Grad3.JPG"
import grad4 from "../../Images/Grad/Grad4.JPG"


const Secondlayer = () => {

    // Slider settings for the slideshow
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
    };

  return (
    <div className="container second-layer col-lg-8 col-8-md col-6-sm">
      <h1>Personal information</h1>

      {/* Location section with heading and paragraph */}
      <div className="location">
        <h2>
          Location <GoLocation />
        </h2>
        <p>
          I've spent my entire life living in Edmonton, Canada. I went to high school at J.H Picard and graduated
          class of 2022. I'm currently in University and I plan to work somewhere in Alberta after I graduate. 
          <br/>Here are some of my high school
          grad photos. <GoSmiley/>
        </p>

        {/* slideshow of grad photos here */}
         <Slider {...settings} className="grad-slider">
          <div>
            <img src={grad1} alt="Graduation 1" className="slide-image" />
          </div>
          <div>
            <img src={grad2} alt="Graduation 2" className="slide-image" />
          </div>
          <div>
            <img src={grad3} alt="Graduation 3" className="slide-image" />
          </div>
          <div>
            <img src={grad4} alt="Graduation 4" className="slide-image" />
          </div>
        </Slider>
      </div>

      <p>When I graduate university, I'll post my university grad photos <GoSmiley/></p>

      {/* Goals section with heading and paragraph */}
      <div className="plans">
        <h2>
          Plans <RiComputerLine />
        </h2>
        <p>
          After university, I plan to start my career in a related computer
          science field. My interests include software development, computer architecture,
          operating systems, and low-level programming. 
        </p>
      </div>

      {/* Social media section with heading and links to github and Linkedin */}
      <div className="media">
        <h2>
          Social Media <IoShareSocialSharp />
        </h2>
        <p>
          Contact me on social media!
          <br />
          Github{" "}
          <a href="https://github.com/NateLapak">
            <BsGithub />
          </a>
          <br />
          Linkedin{" "}
          <a href="https://www.linkedin.com/in/nathan-lapak-a88b2118a/">
            <BsLinkedin />
          </a>
          <br />
        </p>
      </div>
    </div>
  );
};

export default Secondlayer;
